import { Extent } from 'ol/extent';
import { PROJECTION } from '../constants/projection-consts';
import { WfsUrlRoots } from './wfs-consts';

export class WfsHeightLayerUrl {
  constructor(
    private rootURL: string,
    private type: string,
    private subType: string,
    private version: string,
    private outputFormat: string,
    private projection: string
  ) {}

  public get getURLFunction() {
    return (extent: Extent) =>
      `${this.rootURL}${this.type}/1.0.0/WFS?` +
      `version=${this.version}&request=getfeature&typename=${this.subType}&` +
      `outputFormat=${this.outputFormat}&srsname=${this.projection}&` +
      'bbox=' +
      extent.join(',') +
      `,${this.projection}`;
  }
}
export class WfsLayerUrl {
  constructor(
    private rootURL: string,
    private type: string,
    private subType: string,
    private version: string,
    private outputFormat: string,
    private projection: string
  ) {}

  public get getURLFunction() {
    return (extent: Extent) =>
      `${this.rootURL}/${this.type}/ows?service=WFS&` +
      `version=${this.version}&request=GetFeature&typename=${this.subType}&` +
      `outputFormat=${this.outputFormat}&srsname=${this.projection}&` +
      'bbox=' +
      extent.join(',') +
      `,${this.projection}`;
  }

  static fieldShrubberyLayerUrl = new WfsLayerUrl(
    WfsUrlRoots.fvm,
    'GB_og_bioordninger',
    'GB_og_bioordninger:Markkrat',
    '1.0.0',
    'application/json',
    PROJECTION.FEATURE
  );
  static GLMAncientMonumentsLayerUrl = new WfsLayerUrl(
    WfsUrlRoots.fvm,
    'GB_MFO_og_groenne_krav',
    'GB_MFO_og_groenne_krav:GLM_Fortidsminder_2024',
    '1.0.0',
    'application/json',
    PROJECTION.FEATURE
  );
  static GLMLakesLayerUrl = new WfsLayerUrl(
    WfsUrlRoots.fvm,
    'GB_MFO_og_groenne_krav',
    'GB_MFO_og_groenne_krav:GLM_Soer_2024',
    '1.0.0',
    'application/json',
    PROJECTION.FEATURE
  );
  static ThreeMeterBufferLayerUrl = new WfsLayerUrl(
    WfsUrlRoots.fvm,
    'GB_og_bioordninger',
    'GB_og_bioordninger:Braemmer_3-metersbraemme_2025',
    '1.0.0',
    'application/json',
    PROJECTION.FEATURE
  );

  static ProtectedStoneAndEarthLayerUrl = new WfsLayerUrl(
    WfsUrlRoots.geo,
    'dai',
    'dai:bes_sten_jorddiger_2022',
    '1.0.0',
    'application/json',
    PROJECTION.FEATURE
  );
  static NatureLayerUrl = new WfsLayerUrl(WfsUrlRoots.geo, 'dai', 'dai:bes_naturtyper', '1.0.0', 'application/json', PROJECTION.FEATURE);
  static WatercoursesLayerUrl = new WfsLayerUrl(
    WfsUrlRoots.geo,
    'dai',
    'dai:bes_vandloeb',
    '1.0.0',
    'application/json',
    PROJECTION.FEATURE
  );
  static BNBOLayerUrl = new WfsLayerUrl(WfsUrlRoots.geo, 'dai', 'dai:status_bnbo', '1.0.0', 'application/json', PROJECTION.FEATURE);
}
