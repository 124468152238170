<button
  mat-button
  class="cropfilter-btn"
  #cropfilter="matMenuTrigger"
  data-testid="crop-filter-btn"
  [matMenuTriggerFor]="cropfilterMenu"
  (menuOpened)="onMenuStateChange(true)"
  (menuClosed)="onMenuStateChange(false)"
>
  <div class="cropfilter-btn-content">
    <span>{{ 'common.map-controls.cropfilter.title' | translate }}</span>
    <span class="selection-state"> {{ (filterStateTranslateKey$ | async) ?? '' | translate }} </span>
    <mat-icon class="material-symbols-outlined">{{ cropfilter.menuOpen ? 'stat_1' : 'stat_minus_1' }}</mat-icon>
  </div>
</button>

<mat-menu #cropfilterMenu="matMenu" class="cropfilter-menu">
  <ng-template matMenuContent *ngIf="hasAccess$ | async">
    <div class="cropfilter-menu-header" (click)="$event.stopPropagation()">
      <b>{{ 'common.map-controls.cropfilter.title' | translate }}</b>
      <span class="spacer"></span>
      <u *ngIf="isAllSelected()" class="cropfilter-menu-hide-all" data-testid="cropfilter-hide-all-btn" (click)="deselectAll()">{{
        'common.map-controls.deselect-all' | translate
      }}</u>
      <u *ngIf="!isAllSelected()" class="cropfilter-menu-hide-all" data-testid="cropfilter-select-all-btn" (click)="selectAll()">{{
        'common.map-controls.select-all' | translate
      }}</u>
    </div>

    <ng-container [formGroup]="form">
      <div formArrayName="crops">
        <div class="flex-main">
          <span>
            <b>{{ 'common.map-controls.cropfilter.term1' | translate }} </b></span
          >
          <span class="spacer"></span>
          <span class="area-field"
            ><b>{{ cropTotalArea.first | unit: 'ha' : 2 }} ha</b></span
          >
        </div>
        <button
          *ngFor="let crop of firstTermCrops.controls"
          [formGroup]="$any(crop)"
          class="cropfilter-menu-item"
          mat-menu-item
          (click)="toggleCrop(crop); $event.stopPropagation()"
        >
          <div class="cropfilter-menu-item-selection">
            <mat-checkbox class="cropfilter-menu-item-selection-checkbox" formControlName="enabled">
              <div class="flex">
                <span class="circle-icon" [ngStyle]="{ 'background-color': crop.get('color')?.value }"></span>
                <span class="crop-flex" [matTooltip]="crop.get('name')?.value" matTooltipShowDelay="500" matTooltipPosition="below">{{
                  crop.get('name')?.value | truncate: [23, '...']
                }}</span>
                <span class="spacer"></span>
                <span class="area-field">{{ crop.get('totalArea')?.value | unit: 'ha' : 2 }} ha</span>
              </div>
            </mat-checkbox>
          </div>
        </button>
        <div *ngIf="secondTermCrops.controls.length > 0" class="flex-main">
          <span>
            <b>{{ 'common.map-controls.cropfilter.term2' | translate }} </b></span
          >
          <span class="spacer"></span>
          <span class="area-field"
            ><b>{{ cropTotalArea.second | unit: 'ha' : 2 }} ha</b></span
          >
        </div>
        <button
          *ngFor="let crop of secondTermCrops.controls"
          [formGroup]="$any(crop)"
          class="cropfilter-menu-item"
          mat-menu-item
          (click)="toggleCrop(crop); $event.stopPropagation()"
        >
          <div class="cropfilter-menu-item-selection">
            <mat-checkbox class="cropfilter-menu-item-selection-checkbox" formControlName="enabled">
              <div class="flex">
                <span class="circle-icon" [ngStyle]="{ 'background-color': crop.get('color')?.value }"></span>
                <span class="crop-flex" [matTooltip]="crop.get('name')?.value" matTooltipShowDelay="500" matTooltipPosition="below">{{
                  crop.get('name')?.value | truncate: [23, '...']
                }}</span>
                <span class="spacer"></span>
                <span class="area-field">{{ crop.get('totalArea')?.value | unit: 'ha' : 2 }} ha</span>
              </div>
            </mat-checkbox>
          </div>
        </button>
        <div *ngIf="thirdTermCrops.controls.length > 0" class="flex-main">
          <span>
            <b>{{ 'common.map-controls.cropfilter.term3' | translate }} </b></span
          >
          <span class="spacer"></span>
          <span class="area-field"
            ><b>{{ cropTotalArea.third | unit: 'ha' : 2 }} ha</b></span
          >
        </div>
        <button
          *ngFor="let crop of thirdTermCrops.controls"
          [formGroup]="$any(crop)"
          class="cropfilter-menu-item"
          mat-menu-item
          (click)="toggleCrop(crop); $event.stopPropagation()"
        >
          <div class="cropfilter-menu-item-selection">
            <mat-checkbox class="cropfilter-menu-item-selection-checkbox" formControlName="enabled">
              <div class="flex">
                <span class="circle-icon" [ngStyle]="{ 'background-color': crop.get('color')?.value }"></span>
                <span class="crop-flex" [matTooltip]="crop.get('name')?.value" matTooltipShowDelay="500" matTooltipPosition="below">{{
                  crop.get('name')?.value | truncate: [23, '...']
                }}</span>
                <span class="spacer"></span>
                <span class="area-field">{{ crop.get('totalArea')?.value | unit: 'ha' : 2 }} ha</span>
              </div>
            </mat-checkbox>
          </div>
        </button>
      </div>
    </ng-container>
  </ng-template>
</mat-menu>
