<div class="registration">
  <h4>{{ 'main.potatoBlight.registration.header' | translate }}</h4>
  <div class="form-wrapper" *ngIf="registrationEnabled; else registrationDisabled">
    <form class="registration-form" novalidate [formGroup]="registrationForm">
      <div class="registration-selection-wrapper">
        <div class="registration-label">{{ 'main.potatoBlight.registration.isRegistration' | translate }}</div>
        <app-checkbox class="blight-discored-checkbox" formControlName="blightDiscovered"></app-checkbox>
      </div>
      <div class="date-wrapper">
        <div class="date-label">{{ 'main.potatoBlight.registration.date-label' | translate }}</div>
        <app-date-picker formControlName="date" [simple]="true" [minDate]="lowerBoundDate" [maxDate]="upperBoundDate"></app-date-picker>
      </div>
      <div>
        <mat-error class="custom-error" *ngIf="registrationForm.get('date')?.hasError('required')">
          {{ 'main.potatoBlight.registration.dateRequired' | translate }}
        </mat-error>
        <mat-error class="custom-error" *ngIf="registrationForm.get('date')?.hasError('maxDate')">
          {{ 'main.potatoBlight.registration.dateOutOfBound' | translate }}
        </mat-error>
        <mat-error class="custom-error" *ngIf="registrationForm.get('date')?.hasError('minDate')">
          {{ 'main.potatoBlight.registration.dateOutOfBound' | translate }}
        </mat-error>
      </div>
      <div class="button-wrapper">
        <app-square-button [disabled]="registrationForm.invalid" mat-button buttonType="Primary" (click)="saveRegistration()">{{
          'main.potatoBlight.registration.save' | translate
        }}</app-square-button>
      </div>
    </form>
  </div>
  <ng-template #registrationDisabled>
    {{ 'main.potatoBlight.registration.registrationStartsLater' | translate }}
  </ng-template>
</div>
