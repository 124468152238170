import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { EndpointsService } from '../endpoints/endpoints.service';

export const redirectUrlLangExtensionMap: Record<string, string> = {
  dk: '',
  com: 'en',
  de: 'de',
};

@Injectable({
  providedIn: 'root',
})
export class FrontPageRedirectGuard {
  constructor(private endpointService: EndpointsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const referrerDomain = document.referrer ? new URL(document.referrer).hostname.split('.').pop() : null;

    // Determine base URL
    const isExternalUrl = route.data['redirectUrl'].startsWith('http');
    const baseUrl = isExternalUrl ? route.data['redirectUrl'] : this.endpointService.notAuthenticatedRedirectUrl;

    // Prioritize referrer domain extension, fall back to current domain if not available
    const domainExtension = referrerDomain || window.location.hostname.split('.').pop() || 'dk';

    // Get mapped extension based on domain
    const mappedExtension = redirectUrlLangExtensionMap[domainExtension];

    // Construct final URL
    const finalUrl = mappedExtension ? `${baseUrl}${mappedExtension}` : baseUrl;
    console.log('Redirecting to:', finalUrl);
    window.location.href = finalUrl;

    return true;
  }
}
