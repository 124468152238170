import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FieldEntry } from '@app/farm-tasks-overview/class/field-entry';
import { FarmStateService } from '@app/state/services/farm/farm-state.service';
import { TranslateService } from '@ngx-translate/core';
import { wktToGeoJSON } from '@terraformer/wkt';

@Component({
  selector: 'app-minimal-field-card',
  templateUrl: './minimal-field-card.component.html',
  styleUrls: ['minimal-field-card.component.scss'],
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MinimalFieldCardComponent implements OnInit {
  @Input() public fieldEntry!: FieldEntry;

  protected polygon: GeoJSON.Polygon | undefined;
  protected fieldToolTip: string = '';
  protected cropOutlineColor: string = '#ffffff';

  constructor(
    private _translateService: TranslateService,
    private _farmStateService: FarmStateService
  ) {}
  ngOnInit(): void {
    if (this.fieldEntry.polygon) {
      this.polygon = wktToGeoJSON(this.fieldEntry.polygon ?? '') as GeoJSON.Polygon;
      this.cropOutlineColor = darkerStrokeColor(this.fieldEntry.cropColor ?? '#ffffff');
    }

    this._farmStateService.selectedFarms$.subscribe((farms) => {
      const farmName = farms.find((farm) => farm.id === this.fieldEntry.farmId)?.name;
      this.fieldToolTip = this.createToolTipText(this.fieldEntry, farmName);
    });
  }

  private createToolTipText(field: FieldEntry, farmName?: string) {
    const fieldName = field.name;
    const isMainCrop = field.isMainCrop;
    const term = field.successionNo;
    const cropName = field.cropName;

    const farmNameKey = this._translateService.instant('main.overview.field-column.tooltip.farm-name');
    const fieldNameKey = this._translateService.instant('main.overview.field-column.tooltip.field-name');
    const isMainCropKey = this._translateService.instant('main.overview.field-column.tooltip.main-crop');
    const cropNameKey = this._translateService.instant('main.overview.field-column.tooltip.crop-name');
    const termKey = this._translateService.instant('main.overview.field-column.tooltip.term');
    const yesKey = this._translateService.instant('main.overview.field-column.tooltip.yes');
    const NoKey = this._translateService.instant('main.overview.field-column.tooltip.no');
    let tooltipString = ``;

    if (fieldName) {
      tooltipString += fieldNameKey + fieldName + '\n';
    }

    if (farmName) {
      tooltipString += cropNameKey + cropName + '\n';
    }

    if (cropName) {
      tooltipString += farmNameKey + farmName + '\n';
    }
    if (isMainCrop) {
      tooltipString += isMainCropKey + yesKey + '\n';
    } else {
      tooltipString += isMainCropKey + NoKey + '\n';
      tooltipString += termKey + term + '\n';
    }
    return tooltipString;
  }
}

function darkerStrokeColor(hexColor: string): string {
  // If the hex color has 4 characters (3-digit shorthand), double each character
  const hex = hexColor.length === 4 ? hexColor.replace(/./g, '$&$&') : hexColor;

  // Extract the RGBA values from the hex color and convert to integers
  const rgbaValues = hex.match(/\w\w/g)!.map((x) => parseInt(x, 16));

  // If the input hex color has an alpha value, extract it; otherwise, set alpha to 1
  const [r, g, b, a] = rgbaValues.length === 4 ? rgbaValues : [...rgbaValues, 1];

  // Return the darker RGBA color string by multiplying each RGB value by 0.8 and maintaining the alpha value
  return `rgba(${r * 0.8}, ${g * 0.8}, ${b * 0.8}, ${a})`;
}
