<button
  mat-button
  class="machineeventfilter-btn"
  data-testid="machineEvent-filter-btn"
  #machineeventfilter="matMenuTrigger"
  [matMenuTriggerFor]="machineeventfilterMenu"
  (menuOpened)="onMenuStateChange(true)"
  (menuClosed)="onMenuStateChange(false)"
>
  <div class="machineeventfilter-btn-content">
    <span>{{ 'common.map-controls.machineeventfilter.title' | translate }}</span>
    <span class="selection-state"> {{ (filterStateTranslateKey$ | async) ?? '' | translate }} </span>
    <mat-icon class="material-symbols-outlined">{{ machineeventfilter.menuOpen ? 'stat_1' : 'stat_minus_1' }}</mat-icon>
  </div>
</button>

<mat-menu #machineeventfilterMenu="matMenu" class="machineeventfilter-menu">
  <ng-template matMenuContent *ngIf="(hasAccess$ | async) && machineevents.controls.length > 0; else noEvents">
    <div class="machineeventfilter-menu-header" (click)="$event.stopPropagation()">
      <b>{{ 'common.map-controls.machineeventfilter.title' | translate }}</b>
      <span class="spacer"></span>
      @if (isAllSelected()) {
        <u class="machineeventfilter-menu-hide-all" (click)="deselectAll()">
          {{ 'common.map-controls.deselect-all' | translate }}
        </u>
      } @else {
        <u class="machineeventfilter-menu-hide-all" (click)="selectAll()">
          {{ 'common.map-controls.select-all' | translate }}
        </u>
      }
    </div>

    <ng-container [formGroup]="form">
      <button
        *ngFor="let machineevent of machineevents.controls"
        [formGroup]="$any(machineevent)"
        class="machineeventfilter-menu-item"
        mat-menu-item
        (click)="toggleMachineEvent(); $event.stopPropagation()"
      >
        <div class="cropfilter-menu-item-selection">
          <mat-checkbox class="cropfilter-menu-item-selection-checkbox" formControlName="enabled">
            <div class="flex">
              <span class="circle-icon" [ngStyle]="{ 'background-color': machineevent.get('color')?.value }"></span>
              <span
                class="machineevent-flex"
                [matTooltip]="machineevent.get('name')?.value"
                matTooltipShowDelay="500"
                matTooltipPosition="below"
                >{{ machineevent.get('name')?.value | truncate: [20, '...'] }}</span
              >
            </div>
          </mat-checkbox>
        </div>
      </button>
    </ng-container>
  </ng-template>
</mat-menu>
<ng-template #noEvents matMenuContent>
  <div class="no-events">
    <mat-icon class="material-symbols-outlined">info</mat-icon>
    <span>{{ 'common.map-controls.machineeventfilter.no-events' | translate }}</span>
  </div>
</ng-template>
